<template>
  <div class="AdvertisMgrList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-datepicker label="创建时间" defaultTimeType="month" :startTime.sync="searchParams.fromIntime" :endTime.sync="searchParams.toIntime" type="rangedatetimer"></v-datepicker>
        <v-input label="标题" v-model="searchParams.title"></v-input>
        <v-select label="所属App" v-model="searchParams.type" :options="typeOps"></v-select>
        <v-select label="跳转类型" v-model="searchParams.focusType" :options="focusTypeOps"></v-select>
        <v-select2 label="发布项目" v-model="searchParams.publishCommunityId" v-bind="communityParams"></v-select2>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
      </template>
      <template #operateSlot="scope">
          <v-button type="text" v-show="scope.row.editable" permission="update" @click="edit(scope.row)">{{scope.row.status === 1 ? '编辑' : '查看'}}</v-button>
          <v-button text="管理发布项目" v-show="scope.row.editable" type="text" permission="manage_publish" @click="goPageCommunity(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL } from './api'
import {
  communityParams
} from 'common/select2Params'
import {
  setStatusOps,
  setTypeOps,
  setFocusTypeOps
} from './map'
import { createImgVNode, createAlinkVNode } from 'common/vdom'
export default {
  name: 'AdvertisMgrList',
  data () {
    let _this = this
    return {
      searchUrl: getListURL,
      statusOps: setStatusOps(1),
      typeOps: setTypeOps(1),
      focusTypeOps: setFocusTypeOps(1),
      communityParams,
      searchParams: {
        fromIntime: '',
        toIntime: '',
        title: '',
        type: undefined,
        focusType: undefined,
        publishCommunityId: '',
        status: undefined
      },
      headers: [
        {
          prop: 'smallPic',
          label: '广告图',
          align: 'center',
          formatter: (row, prop) => {
            let opts = { width: '75px', height: '128px' }
            return createImgVNode(this, row, prop, opts)
          }
        },
        {
          prop: 'title',
          label: '标题'
        },
        {
          prop: 'publishCommunityNum',
          label: '发布项目',
          formatter: (row, prop) => {
            if (_this.managePublish && row.editable) {
              let opts = {
                cb: _this.goPageCommunity,
                text: row.publishCommunityNum
              }
              return createAlinkVNode(this, row, prop, opts)
            } else {
              return row.publishCommunityNum
            }
          },
          align: 'right'
        },
        {
          prop: 'focusTypeName',
          label: '跳转类型'
        },
        {
          prop: 'typeName',
          label: '所属APP'
        },
        {
          prop: 'timeRange',
          label: '上线时间段',
          formatter (row) {
            return `${row.onlineFromDateStr}~${row.onlineToDateStr}`
          }
        },
        {
          prop: 'statusName',
          label: '状态'
        },
        {
          prop: 'inuserName',
          label: '创建人'
        },
        {
          prop: 'intimeStr',
          label: '创建时间'
        }
      ]
    }
  },
  computed: {
    managePublish () { return this.$store.getters.getPermission('manage_publish') }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'advertisMgrForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'advertisMgrForm',
        query: {
          id: row.id
        }
      })
    },
    goPageCommunity (row) {
      this.$router.push({
        name: 'publishProjectsList',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
