var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AdvertisMgrList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-datepicker", {
                  attrs: {
                    label: "创建时间",
                    defaultTimeType: "month",
                    startTime: _vm.searchParams.fromIntime,
                    endTime: _vm.searchParams.toIntime,
                    type: "rangedatetimer",
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "fromIntime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "fromIntime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "toIntime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "toIntime", $event)
                    },
                  },
                }),
                _c("v-input", {
                  attrs: { label: "标题" },
                  model: {
                    value: _vm.searchParams.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "title", $$v)
                    },
                    expression: "searchParams.title",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "所属App", options: _vm.typeOps },
                  model: {
                    value: _vm.searchParams.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "type", $$v)
                    },
                    expression: "searchParams.type",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "跳转类型", options: _vm.focusTypeOps },
                  model: {
                    value: _vm.searchParams.focusType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "focusType", $$v)
                    },
                    expression: "searchParams.focusType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "发布项目" },
                      model: {
                        value: _vm.searchParams.publishCommunityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "publishCommunityId", $$v)
                        },
                        expression: "searchParams.publishCommunityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c(
                  "v-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: scope.row.editable,
                        expression: "scope.row.editable",
                      },
                    ],
                    attrs: { type: "text", permission: "update" },
                    on: {
                      click: function ($event) {
                        return _vm.edit(scope.row)
                      },
                    },
                  },
                  [_vm._v(_vm._s(scope.row.status === 1 ? "编辑" : "查看"))]
                ),
                _c("v-button", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: scope.row.editable,
                      expression: "scope.row.editable",
                    },
                  ],
                  attrs: {
                    text: "管理发布项目",
                    type: "text",
                    permission: "manage_publish",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.goPageCommunity(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }